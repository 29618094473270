import React, { Component } from "react"
import { Link } from "gatsby"

export default class ComparePlans extends Component {
  render() {
    return (
      <ul className="list-unstyled mb-4">
        <li className="u-pricing-v2__list-item py-2 jd-pricing-v2__li">
          <Link to='/pt/pricing/'>Comparar planos</Link>
        </li>
      </ul>
    )
  }
}


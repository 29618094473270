import React from "react"

import Layout from "../layouts/pt"
import SEO from "../components/SEO"
import Metomic from "../components/Metomic"
import SplashScreens from "../components/SplashScreens"
import Header from "../components/Header.pt"
import HeroSection from "../components/HeroSection"
import WhyJubarte from "../components/WhyJubarte"
import HowItWorks from "../components/HowItWorks"
import Plans from "../components/Plans.pt"
import FAQ from "../components/FAQ"
import Portfolio from "../components/Portfolio"
import JubarteIsFor from "../components/JubarteIsFor"

//Content
import plans from "../content/Plans-pt.json"

const planImages = {
  beachPlanIcon: require("../assets/svg/flat-icons/jd-icons-plans-star.svg"),
  beachPlanDivider: require("../assets/svg/flat-icons/jd-divider-plans_blue.svg"),
  seaPlanIcon: require("../assets/svg/flat-icons/jd-icons-plans-fish.svg"),
  seaPlanDivider: require("../assets/svg/flat-icons/jd-divider-plans_blue.svg"),
  oceanPlanIcon: require("../assets/svg/flat-icons/jd-icons-plans-jelly.svg"),
  oceanPlanDivider: require("../assets/svg/flat-icons/jd-divider-plans_agua.svg"),
}

const heroContent = {
  title: "Design Gráfico",
  subtitle: "Ilimitado",
  titleClass: "jd-uppercase-hero",
  subtitleClass: "jd-special-hero",
  text: "Tenha uma equipe de designers trabalhando para você. Sem contratos, entrevistas ou drama!",
  btnType: "internal",
  btnText: "Ver Planos",
  btnLink: "/",
  loginButtonText: "Entrar",
  locale: "pt",
  svg: require("../assets/svg/flat-icons/jd-hero.svg"),
}

const whyContent = [
  {
    svg: require("../assets/svg/flat-icons/jd-icons-unlimited-design.svg"),
    title: "DESIGNS ILIMITADOS",
    text:
      "Isso mesmo, designs e revisões ilimitadas! Você pode solicitar quantos designs desejar, alterar o conceito quantas vezes quiser e pedir quantas revisões forem necessárias até que fique 100% feliz com seu projeto!",
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-its-yours.svg"),
    title: "O QUE É SEU É SEU!",
    text:
      "Tudo que criamos para você é seu! Você possui todos os direitos e licenças para todos os designs que nós criarmos. Se o seu plano incluir banco de imagens você também receberá as licenças das fotos que forem usadas.",
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-no-contracts.svg"),
    title: "SEM CONTRATOS",
    text:
      "Nós trabalhamos com o modelo de assinatura mês a mês, sem contratos, permitindo a alteração de plano a qualquer momento. Se desejar cancelar, você pode fazê-lo rapidamente e sem questionamentos da nossa parte—mas sentiremos sua falta por aqui.",
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-tools.svg"),
    title: "FERRAMENTAS DE DESIGN",
    text:
      "Nós queremos que tudo saia perfeito e você tenha acesso ao que há de melhor! Por isso usamos as versões mais recentes do Adobe Creative Suite, que inclui o Photoshop, o Illustrator e o InDesign—as ferramentas mais utilizadas nas criações dos projetos criativos.",
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-support-chat-email.svg"),
    title: "SUPORTE POR CHAT & EMAIL",
    text:
      "Vamos adorar saber mais sobre suas dúvidas e de que forma podemos te ajudar. Damos suporte em tempo-real por chat, e em até 24h por email. Você também pode falar com seu designer e gerente de conta por estes mesmos canais.",
  },
  {
    svg: require("../assets/svg/flat-icons/jd-icons-safe-data.svg"),
    title: "SEUS DADOS SEMPRE SEGUROS",
    text:
      "Apreciamos a confiança que você deposita em nós e por isso nos responsabilizamos por proteger e tratar com sigilo as suas informações. Durma tranquilo sabendo que seus dados estão seguros conosco!",
  },
]

const howItWorksSteps = [
  {
    title: "Pedido",
    text:
      "Assim que estiver tudo pronto em nosso sistema, basta enviar um e-mail ou preencher o  formulário na nossa plataforma de trabalho informando os requisitos de seu projeto.",
  },
  {
    title: "Design",
    text:
      "Seu designer trabalhará e enviará o design para sua aprovação. Faremos todas as alterações que você pedir até que esteja absolutamente perfeito.",
  },
  {
    title: "Entrega",
    text: "Depois de ter aprovado seu design, baixe o arquivo e já estaremos prontos para iniciar seu próximo projeto!",
  },
]

const faqContent = [
  {
    title: "Como assim design ilimitados?",
    text:
      '"Você pode solicitar quantos designs desejar. Seus pedidos serão processados um a um, na ordem em que são recebidos, o mais rápido possível. Ou seja, trabalharemos em uma demanda de cada vez e, quando você estiver satisfeito com o seu pedido, iniciaremos imediatamente o próximo."',
  },
  {
    title: "E as revisões também são ilimitadas?",
    text:
      '"São ilimitadas sim! Nós queremos que você seja completamente feliz com o seu design. Peça quantos ajustes forem necessários e nós trabalharemos em seu projeto até que você fique satisfeito!"',
  },
  {
    title: "Quanto tempo leva para concluir uma tarefa de design?",
    text:
      '"Começaremos a trabalhar no seu pedido dentro de algumas horas úteis após recebê-lo. Em seguida, entregaremos seu pedido para revisão entre 2 e 5 dias úteis, dependendo do seu plano e da complexidade do seu design. Se quiser que façamos outras revisões, continuaremos trabalhando até que você esteja feliz."',
  },
  {
    title: "E se eu tiver um pedido de design complexo?",
    text:
      '"Nos diga o que você precisa! Avaliaremos e se pudermos de atender, fornecemos uma cotação de tempo personalizada."',
  },
  {
    title: "Quanto tempo leva uma revisão?",
    text: `"Normalmente cada revisão leva no mínimo um dia útil. Entretanto pode acontecer de você pode precisar de uma alteração mais complexa e que tomará mais tempo. Nestes casos, fornecemos uma 'cotação de tempo para ajuste'."`,
  },
  {
    title: "Então ... e tudo isso sem contratos?",
    text:
      '"Exatamente, sem contratos!! Trabalhamos com um modelo de assinatura mensal. Isso significa que, se você quiser usar nossos serviços apenas por um mês, tudo bem."',
  },
  {
    title: "Posso mudar meu plano a qualquer momento?",
    text:
      '"Claro que sim! Você pode alterar seu plano sempre que precisar. Basta entrar em contato conosco no email suporte@jubarte.design."',
  },
  {
    title: "Existem taxas ocultas?",
    text:
      '"Absolutamente sem taxas escondidas. O preço do pacote é exatamente o que você paga mensalmente. Todos os impostos e taxas estão incluídos nesse preço."',
  },
  {
    title: "O que a Jubarte não faz?",
    text:
      '"Aqui temos alguns exemplos de projetos que não fazemos: edição de vídeo, animações, CAD, trabalho em 3D, programação e codificação. Mesmo assim, se você quiser verificar algum projeto específico, não pense duas vezes e entre em contato conosco."',
  },
]

const portfolioTabsContent = [
  {
    title: "Tudo",
    ariaLabel: "Mostra portfólio completo",
  },
  {
    title: "Logos & Identidades",
    ariaLabel: "Mostra portfólio de Logos & Identidades",
  },
  {
    title: "Web-design & Mídias",
    ariaLabel: "Mostra portfólio de Web-design & Mídias",
  },
  {
    title: "Designs Gráficos",
    ariaLabel: "Mostra portfólio de Designs Gráficos",
  },
  {
    title: "Arte & Ilustrações",
    ariaLabel: "Mostra portfólio de Arte & Ilustrações",
  },
]

const portfolioImageGridContent = [
  {
    key: 1,
    alt: "Design Gráfico - 40ft Shipping Container Homes - Revista",
    imageJPG: require("../assets/images/760x720/graphic_design_40ft_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_40ft_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 2,
    alt: "Logo Design - Margaret River",
    imageJPG: require("../assets/images/760x540/logo_design_margaret_river.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_margaret_river.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 3,
    alt: "Design Gráfico - René Bueno - Cartão de Visita",
    imageJPG: require("../assets/images/760x540/graphic_design_rene_bueno.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_rene_bueno.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 4,
    alt: "App Design - Hikefit",
    imageJPG: require("../assets/images/1520x1080/app_design_hikefit.jpg"),
    imageWEBP: require("../assets/images/1520x1080/app_design_hikefit.webp"),
    category: [portfolioTabsContent[2]],
  },
  {
    key: 5,
    alt: "Logo Design - Logyc",
    imageJPG: require("../assets/images/760x540/logo_design_logyc.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_logyc.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 6,
    alt: "Design Gráfico - Riso di Cielo - Embalagem de Risoto",
    imageJPG: require("../assets/images/760x720/graphic_design_riso_di_cielo.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_riso_di_cielo.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 7,
    alt: "Design Gráfico - Barro Bueno - Imagem Social Media",
    imageJPG: require("../assets/images/760x540/graphic_design_barro_bueno_3.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_barro_bueno_3.webp"),
    category: [portfolioTabsContent[2], portfolioTabsContent[3]],
  },
  {
    key: 8,
    alt: "Design Gráfico - OneStone - Embalagem de Bebidas e Marca",
    imageJPG: require("../assets/images/760x720/graphic_design_onestone_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_onestone_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 9,
    alt: "Logo Design - Barro Bueno",
    imageJPG: require("../assets/images/760x540/logo_design_barro_bueno.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_barro_bueno.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 10,
    alt: "Design Gráfico - Logyc Itens de Papelaria e Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_logyc.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_logyc.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 11,
    alt: "Design Gráfico - Margaret River - Brochura",
    imageJPG: require("../assets/images/760x540/graphic_design_margaret_river_1.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_margaret_river_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 12,
    alt: "Design Gráfico - Skafundro - Illustração e Etiqueta",
    imageJPG: require("../assets/images/760x540/graphic_design_skafundro.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_skafundro.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 13,
    alt: "Design Gráfico - Brimir - Ilustração e Cartão de Visita",
    imageJPG: require("../assets/images/760x540/graphic_design_brimir_3.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_brimir_3.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 14,
    alt: "Design Gráfico - Livro 130 - Ilustração e Design de Livro",
    imageJPG: require("../assets/images/760x540/graphic_design_130_book.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_130_book.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 15,
    alt: "Web Design - Find Your Fellow",
    imageJPG: require("../assets/images/1520x1080/web_design_find_your_fellow_2.jpg"),
    imageWEBP: require("../assets/images/1520x1080/web_design_find_your_fellow_2.webp"),
    category: [portfolioTabsContent[2]],
  },
  {
    key: 16,
    alt: "Design Gráfico - OneStone - Embalagem de Bebidas e Marca",
    imageJPG: require("../assets/images/760x720/graphic_design_onestone_2.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_onestone_2.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 17,
    alt: "Design Gráfico - Brimir Cartão de Presente - Ilustração e Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_brimir_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_brimir_1.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 18,
    alt: "Design Gráfico - Logo Ancient Codes - Embalagem de Brinquedo",
    imageJPG: require("../assets/images/760x720/graphic_design_ancient_codes_logo.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_ancient_codes_logo.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 19,
    alt: "Design Gráfico - Barro Bueno - Sacola e Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_barro_bueno_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_barro_bueno_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 20,
    alt: "Logo Design - One Stone",
    imageJPG: require("../assets/images/760x540/logo_design_onestone.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_onestone.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 21,
    alt: "Design Gráfico - Embalagem Brimir - Ilustração e Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_brimir_2.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_brimir_2.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 22,
    alt: "App Design - Notto",
    imageJPG: require("../assets/images/760x720/app_design_notto.jpg"),
    imageWEBP: require("../assets/images/760x720/app_design_notto.webp"),
    category: [portfolioTabsContent[2]],
  },
  {
    key: 23,
    alt: "Design Gráfico - Find Your Fellow - Camiseta e Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_find_your_fellow_1.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_find_your_fellow_1.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 24,
    alt: "Design Gráfico - OneStone - Embalagem de Bebidas e Marca",
    imageJPG: require("../assets/images/760x720/graphic_design_onestone_3.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_onestone_3.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 25,
    alt: "Design Gráfico - Spirits of Lettering - Ilustração e Poster",
    imageJPG: require("../assets/images/760x720/graphic_design_spirits_of_lettering.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_spirits_of_lettering.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
  {
    key: 26,
    alt: "Logo Design - René Bueno",
    imageJPG: require("../assets/images/760x540/logo_design_rene_bueno.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_rene_bueno.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 27,
    alt: "Logo Design - Brimir",
    imageJPG: require("../assets/images/760x540/logo_design_brimir.jpg"),
    imageWEBP: require("../assets/images/760x540/logo_design_brimir.webp"),
    category: [portfolioTabsContent[1]],
  },
  {
    key: 28,
    alt: "Design Gráfico - 40ft Shipping Container Homes - Revista",
    imageJPG: require("../assets/images/760x720/graphic_design_40ft_2.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_40ft_2.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 29,
    alt: "Design Gráfico - Cleusa Vicchiarelli - Caneca",
    imageJPG: require("../assets/images/760x540/graphic_design_cleusa_vicchiarelli.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_cleusa_vicchiarelli.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 30,
    alt: "Design Gráfico - Barro Bueno - Carimbo e Logo",
    imageJPG: require("../assets/images/760x720/graphic_design_barro_bueno_2.jpg"),
    imageWEBP: require("../assets/images/760x720/graphic_design_barro_bueno_2.webp"),
    category: [portfolioTabsContent[3]],
  },
  {
    key: 31,
    alt: "Design Gráfico - Mimicus - Ilustração e Etiqueta",
    imageJPG: require("../assets/images/760x540/graphic_design_mimicus.jpg"),
    imageWEBP: require("../assets/images/760x540/graphic_design_mimicus.webp"),
    category: [portfolioTabsContent[3], portfolioTabsContent[4]],
  },
]

const typingContent = [
  "empresários",
  "investidores",
  "start-ups",
  "grandes empresas",
  "desenvolvedores",
  "investidores",
  "empreendedores",
  "agência digital",
  "blogueiros",
  "freelancers",
]

const IndexPage = (props) => (
  <Layout location={props.location}>
    <Metomic />
    <SEO
      title="Design Gráfico e Web Ilimitado | Jubarte Design"
      locale="pt"
      altLocale="en"
      description="Jubarte Design é uma plataforma que fornece design gráfico e web ilimitado e de alta qualidade através de uma assinatura fixa mensal. Sem contratos, entrevistas ou drama!"
      image="/unlimited-graphic-web-design-pt.jpg"
      imageSquared="/unlimited-graphic-web-design-squared-pt.jpg"
      imageAlt="Jubarte Design Logo - Design Gráfico e Web Ilimitado com uma Taxa Fixa Mensal"
    />
    <SplashScreens />
    <Header showNavLinks showLanguageSelector />

    <div className="home-wrapper">
      <HeroSection content={heroContent} scrollTo="Planos" />

      <JubarteIsFor firstTitle="Jubarte é feita para" lastTitle="como você!" content={typingContent} />

      <WhyJubarte firstTitle="Por Que" mainTitle="Jubarte Design" content={whyContent} />

      <HowItWorks
        scrollTo="Como"
        firstTitle="Como"
        mainTitle="Funciona"
        steps={howItWorksSteps}
        image={require("../assets/svg/flat-icons/jd-how.svg")}
      />

      <Plans scrollTo="Planos" plans={plans} planImages={planImages} comparePlans />

      <FAQ
        scrollTo="FAQ"
        firstTitle="Perguntas"
        mainTitle="Frequentes"
        content={faqContent}
        btnText="Saiba Mais"
        btnLink="/pt/help/"
      />

      <Portfolio
        scrollTo="Portfólio"
        firstTitle="Nosso"
        mainTitle="Portfólio"
        tabs={portfolioTabsContent}
        imageGrid={portfolioImageGridContent}
      />

      <div className="u-space-2-bottom"></div>
    </div>
  </Layout>
)

export default IndexPage
